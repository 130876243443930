class RadioAsync
{

    static rateSong(rating)
    {
        var modal = new LambdaModal();
        modal.setSize(LambdaModalSize.ExtraLarge);
        modal.setLoading();
        modal.render();
        modal.show();

        setTimeout(() =>
        {

            LambdaHTTP.Post(route('async.radio.song.rate', [rating]), true).OnSuccess(data => {

                modal.hide();

            }).OnFail(data => {

                modal.showError(data.Message);
                modal.addCloseButton();

            }).Send();

        }, 500);

    }

}

window.RadioAsync = RadioAsync;
class RadioPackets
{

    PacketHandlers = {};

    constructor()
    {

        var self = this;

        this.PacketHandlers = {
            StatsUpdate: function(stats) { self.onStatsUpdated(stats); },
            RecentlyPlayedUpdate: function(list) { self.onRecentlyPlayedUpdated(list) },
            UserRating: (rating) => { self.onUserRating(rating); },
            InitStats: (init) => { self.onInitStats(init); }
        };
    }

    onPacket(packet)
    {
        var pName = packet.Name;
        var pData = packet.Data;

        if(this.PacketHandlers[pName] != null)
        {
            this.PacketHandlers[pName](pData);
        }
        else
        {
            console.warn(`Unknown packet: ${pName}`);
        }

    }
    
    onInitStats(data)
    {

        RadioUI.statsUpdated(data.Stats);
        RadioUI.updateRecentlyPlayed(data.RecentlyPlayed);

        RadioUI.setLoading(false);

    }

    onStatsUpdated(stats)
    {
        RadioUI.statsUpdated(stats);
    }

    onRecentlyPlayedUpdated(list)
    {
        RadioUI.updateRecentlyPlayed(list);
    }

    onUserRating(rating)
    {
        RadioUI.userRating(rating);
    }

}

window.RadioPackets = RadioPackets;
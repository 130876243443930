class RadioData
{

    static Ratings          = {};
    static RequestSources   = {};
    static ClientTypes      = {
        0: {
            Icon: "fa-solid fa-user-secret",
            Text: "Anonymous"
        },
        1: {
            Icon: "fa-sharp fa-solid fa-lambda",
            Text: "Website"
        },
        2: {
            Icon: "fa-solid fa-gamepad-modern",
            Text: "In-Game"
        },
        3: {
            Icon: "fa-brands fa-discord",
            Text: "Discord"
        }
    };
    static RatingTranslate  = {
        Like: 1,
        Love: 2,
        Meh: 3,
        Hate: 4,
        Funny: 5,
        Angry: 6
    };
    static Games = {};

    static setRatings(ratings)
    {
        ratings.forEach(item => {

            RadioData.Ratings[item.id] = item;

        });
    }

    static setRequestSources(sources)
    {
        sources.forEach(item => {

            RadioData.RequestSources[item.id] = item;

        });
    }

    static setGames(games)
    {
        games.forEach(item => {

            RadioData.Games[item.id] = item;

        });
    }

}

window.RadioData = RadioData;